<template>
  <layout>
    <v-card>
        <v-card-title>
            <v-icon
                large
                left
            >
                mdi-road
            </v-icon>
            <span class="title font-weight-light">แก้ไขข้อมูลบริหารแผน</span>
        </v-card-title>
    </v-card>
    <Confirm ref="confirm"></Confirm>
    <v-card class="my-3">
        <v-card-title>
            <span class="subtitle-1">รายละเอียด</span>
        </v-card-title>
        <v-divider class="mx-4 mb-5" />
        <ValidationObserver ref="form">
            <v-form
                ref="form"
                lazy-validation
            >
                <v-container class="body-2">
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ปีงบประมาณ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="fiscalYear"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="fiscalYear"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ชื่อแผน</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="planName"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="planName"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ลิ้งค์ไฟล์เอกสาร</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="linkFile"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="linkFile"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ไตรมาส 1</v-subheader>
                        </v-col>
                        <v-col cols="3">
                           
                            <v-select
                                :items="statusPlanItems"
                                v-model="quarter1"
                                item-text="statusPlanName"
                                item-value="statusPlanName"
                                dense
                                outlined
                                clearable
                            ></v-select>

                        </v-col>
                        <v-col cols="6" v-if="quarter1=='ไม่บรรลุ'">
                            <validation-provider
                                v-slot="{ errors }"
                                name="quarter1Remark"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="quarter1Remark"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                    label="เหตุผล"
                                    placeholder="ระบุเหตุผล"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ไตรมาส 2</v-subheader>
                        </v-col>
                        <v-col cols="3">
                            <v-select
                                :items="statusPlanItems"
                                v-model="quarter2"
                                item-text="statusPlanName"
                                item-value="statusPlanName"
                                dense
                                outlined
                                clearable
                            ></v-select>

                        </v-col>
                        <v-col cols="6" v-if="quarter2=='ไม่บรรลุ'">
                            <validation-provider
                                v-slot="{ errors }"
                                name="quarter2Remark"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="quarter2Remark"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                    label="เหตุผล"
                                    placeholder="ระบุเหตุผล"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ไตรมาส 3</v-subheader>
                        </v-col>
                        <v-col cols="3">
                            <v-select
                                :items="statusPlanItems"
                                v-model="quarter3"
                                item-text="statusPlanName"
                                item-value="statusPlanName"
                                dense
                                outlined
                                clearable
                            ></v-select>

                        </v-col>
                        <v-col cols="6" v-if="quarter3=='ไม่บรรลุ'">
                            <validation-provider
                                v-slot="{ errors }"
                                name="quarter3Remark"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="quarter3Remark"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                    label="เหตุผล"
                                    placeholder="ระบุเหตุผล"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ไตรมาส 4</v-subheader>
                        </v-col>
                        <v-col cols="3">
                            <v-select
                                :items="statusPlanItems"
                                v-model="quarter4"
                                item-text="statusPlanName"
                                item-value="statusPlanName"
                                dense
                                outlined
                                clearable
                            ></v-select>

                        </v-col>
                        <v-col cols="6" v-if="quarter4=='ไม่บรรลุ'">
                            <validation-provider
                                v-slot="{ errors }"
                                name="quarter4Remark"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="quarter4Remark"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                    label="เหตุผล"
                                    placeholder="ระบุเหตุผล"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    
                    <v-divider class="mx-4 mb-5" />
                    <v-row>
                        <v-col>
                            <v-btn depressed color="success" @click="save()">
                                บันทึก
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </ValidationObserver>
    </v-card>
  </layout>
</template>
<script>
import Api from "@/api/Api";

export default {
    props: ['planId'],
    data: () => ({
        fiscalYear: "",
        planName: "",
        linkFile: "",
        quarter1: null,
        quarter2: null,
        quarter3: null,
        quarter4: null,
        statusPlanItems: [
            "บรรลุ",
            "ไม่บรรลุ"
        ],
        quarter1Remark: "",
        quarter2Remark: "",
        quarter3Remark: "",
        quarter4Remark: "",
        formChkErrors: false,
    }),
    async mounted() {
      this.$store.commit('setLoading', true)
      await this.getData()
      this.$store.commit('setLoading', false)
    },
    methods: {
        async getData() {
            try {
                const res = (await Api().get('plan/' + this.planId)).data;

                console.log(res.data)

                if (res.status == 'success') {
                    this.fiscalYear = res.data.fiscal_year;
                    this.planName = res.data.plan_name;
                    this.linkFile = res.data.link_file;
                    this.quarter1 = res.data.quarter1;
                    this.quarter2 = res.data.quarter2;
                    this.quarter3 = res.data.quarter3;
                    this.quarter4 = res.data.quarter4
                    this.quarter1Remark = res.data.quarter1_remark
                    this.quarter2Remark = res.data.quarter2_remark
                    this.quarter3Remark = res.data.quarter3_remark
                    this.quarter4Remark = res.data.quarter4_remark

                } else {
                    console.log(res.data);
                }

            } catch (err) {
                console.log(err.message);
            }
        },
        async save() {
            this.$store.commit('setLoading', true)
            const req = {
                fiscal_year: this.fiscalYear,
                plan_name: this.planName,
                link_file: this.linkFile,
                quarter1: this.quarter1,
                quarter2: this.quarter2,
                quarter3: this.quarter3,
                quarter4: this.quarter4,
                quarter1_remark: this.quarter1Remark,
                quarter2_remark: this.quarter2Remark,
                quarter3_remark: this.quarter3Remark,
                quarter4_remark: this.quarter4Remark,
            }

            this.formChkErrors = false
            this.formChkErrors = this.$refs.form.validate()

            if(this.formChkErrors){
                let res = (await Api().put("plan/" + this.planId, req)).data;

                // console.log('data', res)

                if (res.status == 'success') {
                    this.$store.commit('setLoading', false)
                    await this.$refs.confirm.open('ข้อความ', 'แก้ไขข้อมูลเรียบร้อย', { color: 'primary', noconfirm: true })
                    this.$router.push('/plan')
                }else{
                    this.$store.commit('setLoading', false)
                }
            }else{
                this.$store.commit('setLoading', false)
            }
        }
    }
};
</script>